import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Column } from 'react-table';
import { navigate } from 'gatsby';

import Table from '../../../Table';
import AcknowledgeDialog from '../../../AcknowledgeDialog';
import DeleteButton from './DeleteButton';
import ProgressIndicator from './ProgressIndicator';
import { uploadS3Object } from '../../../../client/aws';
import { FILE } from '../../../../types';
import { ROUTES } from '../../../../utils/constants';

interface UPLOAD_FILE extends FILE {
  file: File;
  status: 'In Progress' | 'Failed' | 'Uploaded' | undefined;
}

interface UPLOAD_FILES {
  [fileName: string]: UPLOAD_FILE;
}

const Upload = () => {
  const hiddenFileInput = useRef(null);
  const [uploading, setUploadingStatus] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [filesToUpload, setUploadFiles] = useState<UPLOAD_FILES>({});

  const columns: Column<UPLOAD_FILE>[] = useMemo(
    () => [
      { Header: 'File name', accessor: 'filename' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Last Modified', accessor: 'lastModified' },
      { Header: 'Upload Status', accessor: 'status' },
      {
        Header: '',
        accessor: 'id',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <DeleteButton
              onClick={() => {
                const clonedFilesToUpload = { ...filesToUpload };
                delete clonedFilesToUpload[original.filename];
                setUploadFiles(clonedFilesToUpload);
                setTotalFileSize(totalFileSize - original.size);
              }}
            >
              Remove
            </DeleteButton>
          );
        },
      },
    ],
    [filesToUpload, totalFileSize]
  );

  useEffect(() => {
    if (uploadingProgress > 0 && uploadingProgress >= totalFileSize) {
      setUploadingStatus(false);
      setCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingProgress]);

  const addFiles = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const filesAdded: UPLOAD_FILES = { ...filesToUpload };
    let accumulatedFileSize = totalFileSize;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      filesAdded[file.name] = {
        id: file.name,
        filename: file.name,
        size: file.size,
        lastModified: new Date(file.lastModified).toLocaleDateString(),
        file,
        status: undefined,
      };
      accumulatedFileSize += file.size;
    }
    setTotalFileSize(accumulatedFileSize);
    setUploadFiles(filesAdded);
  };

  const uploadFiles = () => {
    setUploadingStatus(true);
    setUploadingProgress(0);

    Object.values(filesToUpload).forEach(async (file) => {
      setUploadFiles((prevFilesToUpload) => ({
        ...prevFilesToUpload,
        [file.filename]: {
          ...prevFilesToUpload[file.filename],
          status: 'In Progress',
        },
      }));

      let err;
      try {
        await uploadS3Object(file.filename, file.file);
      } catch (error) {
        err = error;
      }

      setUploadingProgress(
        (prevUploadProgress) => prevUploadProgress + file.size
      );

      setUploadFiles((prevFilesToUpload) => ({
        ...prevFilesToUpload,
        [file.filename]: {
          ...prevFilesToUpload[file.filename],
          status: err ? 'Failed' : 'Uploaded',
        },
      }));
    });
  };

  const files = Object.values(filesToUpload);

  return (
    <Grid container direction="column" spacing={2}>
      <ProgressIndicator
        progress={Math.floor((uploadingProgress / totalFileSize) * 100)}
        visible={uploading}
      />
      <AcknowledgeDialog
        open={completed}
        title="Files Uploaded!"
        content="All the files have been uploaded"
        onClose={() => setCompleted(false)}
      />
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() =>
              navigate(ROUTES.ADMIN_FILE_MANAGEMENT, { replace: true })
            }
          >
            Go back
          </Button>
        </Grid>
        <Grid item xs={2}>
          <input
            type="file"
            multiple
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={addFiles}
          >
            Add files
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Table<UPLOAD_FILE> columns={columns} data={files} />
      </Grid>
      <Grid item container direction="row" justifyContent="center">
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={uploadFiles}
            disabled={files.length === 0}
          >
            Upload files
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Upload;
