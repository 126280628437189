import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Seo from '../../Seo';
import UserList from './UserManagement/UserList';
import FileList from './FileManagement/FileList';
import colors from '../../../styles/colors';

const AntTabs = withStyles({
  root: {
    borderBottom: `1px solid ${colors.primary}`,
  },
  indicator: {
    backgroundColor: colors.danger,
  },
})(Tabs);
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
  >
    <Box p={2}>{children}</Box>
  </div>
);

interface AdminHomeProps extends RouteComponentProps {}

const AdminHome = (props: AdminHomeProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Seo title="Admin" />
      <AntTabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleTabChange}
        aria-label="management tabs"
      >
        <Tab label="User Management" />
        <Tab label="File Management" />
      </AntTabs>
      <TabPanel value={tabIndex} index={0}>
        <UserList />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <FileList />
      </TabPanel>
    </>
  );
};

export default AdminHome;
