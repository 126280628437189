import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { User } from '../../../../types';
import RightDrawer from '../../../RightDrawer';
import LoadingIndicator from '../../../LoadingIndicator';
import { resetUserPassword } from '../../../../client/aws';
import ErrorDialog from '../../../ErrorDialog';
import { VALIDATION_SCHEMA } from '../../../../utils/constants';
import AcknowledgeDialog from '../../../AcknowledgeDialog';

const ValidationSchema = Yup.object().shape({
  newPassword: VALIDATION_SCHEMA.passwordConfirm,
  passwordConfirm: VALIDATION_SCHEMA.passwordConfirm,
});

interface PasswordResetDrawerProps {
  open: boolean;
  user?: User;
  onClose: () => void;
}

const PasswordResetDrawer = ({ open, user, onClose }: PasswordResetDrawerProps) => {
  const [passwordReset, setPasswordReset] = useState(false);
  const [error, setError] = useState<string>();
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik<{ username: string; newPassword: string; passwordConfirm: string; }>({
    validationSchema: ValidationSchema,
    initialValues: {
      username: user.username,
      newPassword: '',
      passwordConfirm: ''
    },
    onSubmit: async ({ newPassword }) => {
      setError(undefined);
      try {
        await resetUserPassword(user.username, newPassword );
        onClose();
        setPasswordReset(true);
      } catch (err) {
        setError(err);
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetForm(), [open]);

  return (
    <>
      <RightDrawer
        open={open}
        onClose={onClose}
        title='Reset User Password'
      >
        <LoadingIndicator loading={isSubmitting} />
        {error && <ErrorDialog error={error} />}
        <form name="reset-password" onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <TextField
                fullWidth
                id="username"
                name="username"
                label="User Name"
                disabled
                value={values.username}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label="New Password"
                type="password"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
                disabled={isSubmitting}
                value={values.passwordConfirm}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                helperText={touched.passwordConfirm && errors.passwordConfirm}
              />
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item xs={12} md={5}>
                <Button
                  onClick={onClose}
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md={5}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!isValid}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </RightDrawer>
      <AcknowledgeDialog
        open={passwordReset}
        title="Password Reset!"
        content='Password has been reset'
        onClose={() => setPasswordReset(false)}
      />
     </>
  );
};

export default PasswordResetDrawer;
