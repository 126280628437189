import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import startCase from 'lodash/startCase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Seo from '../../../Seo';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { User } from '../../../../types';
import { disableUser, enableUser, getUser } from '../../../../client/aws';
import LoadingIndicator from '../../../LoadingIndicator';
import UserDrawer from './UserDrawer';
import PasswordResetDrawer from './PasswordResetDrawer';
import ErrorDialog from '../../../ErrorDialog';
import DetailItems from '../../../DetailItems';
import { USER_CUSTOM_ATTRIBUTES } from '../../../../utils/constants';

interface UserDetailProps
  extends RouteComponentProps<{ username: string }> {}

const UserDetail = ({ username }: UserDetailProps) => {
  const [drawerVisible, setDrawerVisibility] = useState(false);
  const [passwordResetDrawerVisible, setPasswordResetDrawerVisibility] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisibility] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, triggerReload] = useState(false);
  const [error, setError] = useState<string>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    (async () => {
      try {
        const userDetail = await getUser(username);
        setUser(userDetail);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    })();
  }, [username, reload]);

  const closeDrawer = (shouldRefresh: boolean) => {
    setDrawerVisibility(false);
    if (shouldRefresh) {
      triggerReload(!reload);
    }
  };

  const confirmToggling = () => setConfirmationDialogVisibility(true);

  const toggleUserStatus = async () => {
    setConfirmationDialogVisibility(false);
    setLoading(true);
    setError(undefined);
    // TODO show confirmation dialog
    try {
      if (user.enabled) {
        await disableUser(user.username);
      } else {
        await enableUser(user.username);
      }
      triggerReload(!reload);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const userInfo = [
    { label: 'Username', content: user?.username },
    { label: 'Email', content: user?.email },
    { label: 'Phone', content: user?.phone_number },
  ];

  const userStatus = [
    { label: 'User Type', content: startCase(user?.userType) },
    { label: 'Status', content: user?.status },
    { label: 'Enabled', content: user?.enabled ? 'Yes' : 'No' },
  ];

  const userAttributesLeft: { label: string; content: string }[] = [];
  const userAttributesRight: { label: string; content: string }[] = [];

  if (user) {
    USER_CUSTOM_ATTRIBUTES.forEach(({ field, title }, index) => {
      const item = {
        label: title,
        content: Array.isArray(user[field])
          ? (user[field] as string[]).join(', ')
          : user[field]?.toString() || '',
      };

      if (index % 2 === 0) {
        userAttributesLeft.push(item);
      } else {
        userAttributesRight.push(item);
      }
    });
  }

  return (
    <>
      <Seo title="User Detail" />
      <LoadingIndicator loading={loading} />
      {error && <ErrorDialog error={error} />}
      {user && (
        <Grid container direction="column" spacing={5}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={confirmToggling}
              >
                {user.enabled ? 'Disable User' : 'Enable User'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setDrawerVisibility(true)}
              >
                Edit User
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setPasswordResetDrawerVisibility(true)}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Box
              bgcolor="#fff"
              p={4}
              mb={3}
              boxShadow="0 5px 10px 0 rgba(0,0,0,0.15)"
            >
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <DetailItems items={userInfo} />
                </Grid>
                <Grid item xs={6}>
                  <DetailItems items={userStatus} />
                </Grid>
                {user.userType === 'gasAgent' && (
                  <Grid item xs={6}>
                    <DetailItems items={userAttributesLeft} />
                  </Grid>
                )}
                {user.userType === 'gasAgent' && (
                  <Grid item xs={6}>
                    <DetailItems items={userAttributesRight} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <UserDrawer open={drawerVisible} onClose={closeDrawer} user={user} />
          <PasswordResetDrawer open={passwordResetDrawerVisible} onClose={() => setPasswordResetDrawerVisibility(false)} user={user} />
          <ConfirmationDialog
            open={confirmationDialogVisible}
            title={`${user.enabled ? 'Disable' : 'Enable'} User?`}
            content={`You are going to ${
              user.enabled ? 'disable' : 'enable'
            } the user ${user.username}. Do you want to continue?`}
            onClose={() => setConfirmationDialogVisibility(false)}
            onConfirm={() => toggleUserStatus()}
          />
        </Grid>
      )}
    </>
  );
};

export default UserDetail;
