import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Dialog, { DialogHeader } from './Dialog';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
}) => (
  <Dialog
    open={open}
    positiveAction="Yes"
    onPositiveAction={onConfirm}
    negativeAction="No"
    onNegativeAction={onClose}
  >
    <DialogHeader>{title}</DialogHeader>
    <Typography component="div" variant="h4" color="primary">
      <Box lineHeight={2}>{content}</Box>
    </Typography>
  </Dialog>
);
export default ConfirmationDialog;
