import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import startCase from 'lodash/startCase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Table from '../../../Table';
import { getUserList } from '../../../../client/aws';
import { User } from '../../../../types';
import UserDrawer from './UserDrawer';
import { ROUTES } from '../../../../utils/constants';
import ErrorDialog from '../../../ErrorDialog';
import LoadingIndicator from '../../../LoadingIndicator';

const COLUMNS = [
  { Header: 'Id', accessor: 'sub', filter: 'fuzzyText' },
  { Header: 'User name', accessor: 'username', disableFilters: true },
  { Header: 'Email', accessor: 'email', disableFilters: true },
  { Header: 'Full Name', accessor: 'fullName', filter: 'fuzzyText' },
  { Header: 'Phone', accessor: 'phone', disableFilters: true },
  {
    Header: 'User Type',
    accessor: 'userType',
    Cell: ({ value }) => startCase(value),
    disableFilters: true,
  },
  { Header: 'Plant Number', accessor: 'plantNumber', filter: 'fuzzyText' },
  { Header: 'Plant Name', accessor: 'plantName', filter: 'fuzzyText' },
  { Header: 'Status', accessor: 'status', filter: 'text' },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
    filter: 'text',
  },
];

interface UserListProps extends RouteComponentProps {}

const UserList = (props: UserListProps) => {
  const [drawerVisible, setDrawerVisibility] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [reload, triggerReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    (async () => {
      try {
        const userList = await getUserList();
        setUsers(userList);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    })();
  }, [reload]);

  const closeDrawer = (shouldRefresh: boolean) => {
    setDrawerVisibility(false);
    if (shouldRefresh) {
      triggerReload(!reload);
    }
  };

  const createUser = () => {
    setDrawerVisibility(true);
  };

  const importUsers = () => {
    navigate(ROUTES.ADMIN_USER_IMPORT);
  };

  const exportUsers = async () => {
    const HEADERS = [
      // 'Full name',
      'User name',
      'Email',
      'User type',
      'Phone',
      'Plant Number',
      'Plant Name',
      'Is admin',
      'Status',
      'Enabled',
    ].join(',');
    setLoading(true);
    const userList = await getUserList();
    const csvData = userList
      .map(
        ({
          // fullname,
          username,
          email,
          userType,
          phone,
          plantNumber,
          plantName,
          isAdmin,
          status,
          enabled,
        }) =>
          [
            // fullname,
            username,
            email,
            userType,
            phone,
            plantNumber,
            plantName,
            isAdmin,
            status,
            enabled ? 'Yes' : 'No',
          ].join(',')
      )
      .join('\n');
    setLoading(false);
    const blob = new Blob([`${HEADERS}\n${csvData}`], { type: 'text/csv' });

    const a = document.createElement('a');
    a.download = 'users.csv';
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      <Grid container direction="column" spacing={5}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          spacing={5}
        >
          <Grid item>
            <Button color="primary" variant="contained" onClick={createUser}>
              Add User
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={importUsers}>
              Import Users
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={exportUsers}>
              Export Users
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Grid item>
            <ErrorDialog error={error} />
          </Grid>
        )}
        {users && (
          <Grid item>
            <Table<User>
              columns={COLUMNS}
              data={users}
              pagination
              onRowClick={(user) =>
                navigate(`${ROUTES.ADMIN_USER_DETAIL}/${user.username}`)
              }
            />
          </Grid>
        )}
      </Grid>
      <UserDrawer open={drawerVisible} onClose={closeDrawer} />
    </>
  );
};

export default UserList;
