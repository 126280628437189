import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import BackupIcon from '@material-ui/icons/Backup';
import { navigate } from 'gatsby';
import { Column } from 'react-table';

import Table from '../../../Table';
import ConfirmationDialog from '../../../ConfirmationDialog';
import AcknowledgeDialog from '../../../AcknowledgeDialog';
import ErrorDialog from '../../../ErrorDialog';
import DeleteButton from './DeleteButton';
import {
  AWS_BUCKET_NAME,
  AWS_COGNITO_REGION,
  ROUTES,
} from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import { getS3ObjectList, deleteS3Object } from '../../../../client/aws';
import { FILE } from '../../../../types';

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = () => {
  const [fileDeleted, setFileDeleted] = useState(false);
  const [loadingError, setLoadingError] = useState<string>();
  const [deletingError, setDeletingError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [dialogVisiblity, setDialogVisibility] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const [files, setFiles] = useState<FILE[]>([]);

  const columns: Column<FILE>[] = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        Cell: ({ cell: { value } }) => (
          <a
            href={`https://${AWS_BUCKET_NAME}.s3-${AWS_COGNITO_REGION}.amazonaws.com/${value}`}
          >
            {value}
          </a>
        ),
      },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Last Modified', accessor: 'lastModified' },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell: { value } }) => (
          <DeleteButton
            onClick={() => {
              setFileToDelete(value);
              setDialogVisibility(true);
            }}
          >
            Delete
          </DeleteButton>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setLoadingError(undefined);
        setFiles([]);
        const objectList = await getS3ObjectList();
        const fileList = objectList.map(({ Key, LastModified, Size }) => ({
          id: Key,
          filename: Key,
          size: Size,
          lastModified: LastModified.toLocaleDateString(),
        }));
        setFiles(fileList);
      } catch (err) {
        setLoadingError('Can not load files');
      }
      setLoading(false);
    })();
  }, []);

  const uploadFile = () => {
    navigate(ROUTES.ADMIN_FILE_MANAGEMENT_UPLOAD);
  };

  const deleteFile = async (name: string) => {
    try {
      setDeletingError(undefined);
      await deleteS3Object(name);
      // remove the deleted file.
      setFiles(files.filter(({ filename }) => filename !== name));
      setFileDeleted(true);
    } catch (err) {
      setDeletingError(err);
    }
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      <Grid container direction="column" spacing={5}>
        <Grid item container direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={uploadFile}
            endIcon={<BackupIcon />}
          >
            Upload
          </Button>
        </Grid>
        <Grid item>
          {loadingError ? (
            <ErrorDialog error={loadingError} />
          ) : (
            <Table<FILE> columns={columns} data={files} />
          )}
        </Grid>
      </Grid>
      {deletingError && <ErrorDialog error={deletingError} />}
      <AcknowledgeDialog
        open={fileDeleted}
        title="File Deleted!"
        content={`${fileToDelete} has been deleted`}
        onClose={() => setFileDeleted(false)}
      />
      <ConfirmationDialog
        title="Delete File?"
        content={`You are going to delete file ${fileToDelete}. Do you want to continue?`}
        open={dialogVisiblity}
        onClose={() => setDialogVisibility(false)}
        onConfirm={() => {
          setDialogVisibility(false);
          setLoading(true);
          deleteFile(fileToDelete);
          setLoading(false);
        }}
      />
    </>
  );
};

export default Home;
