import React from 'react';
import { Router } from '@reach/router';

import FileUpload from './Upload';
import AdminRoute from '../../../AdminRoute';

const FileManagement = () => {
  return (
    <Router>
      <AdminRoute path="upload" component={FileUpload} />
    </Router>
  );
};

export default FileManagement;
