import React, { useState, useRef, ChangeEvent } from 'react';
import { RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby';
import Papa from 'papaparse';
import camelCase from 'lodash/camelCase';

import AcknowledgeDialog from '../../../AcknowledgeDialog';
import Seo from '../../../Seo';
import Table from '../../../Table';
import { User } from '../../../../types';
import { ROUTES } from '../../../../utils/constants';
import ErrorDialog from '../../../ErrorDialog';
import LoadingIndicator from '../../../LoadingIndicator';
import { updateUser } from '../../../../client/aws';

const COLUMNS = [
  { Header: 'User name', accessor: 'username', disableFilters: true },
  { Header: 'Email', accessor: 'email', disableFilters: true },
  { Header: 'Phone', accessor: 'phone', disableFilters: true },
  {
    Header: 'Admin',
    accessor: 'isAdmin',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
    disableFilters: true,
  },
];

interface UserImportProps extends RouteComponentProps {}

const UserImport = (props: UserImportProps) => {
  const [importedUsers, setImportedUsers] = useState<User[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [usersImported, setUsersImported] = useState(false);
  const hiddenFileInput = useRef(null);

  const selectCSV = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const csvFile = event.target.files[0];
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (h) => {
        return camelCase(h);
      },
      complete: (result) => {
        const trimmedResult = result.data.map((item) => {
          const trimmedItem = { ...item };
          Object.keys(trimmedItem).forEach((key) => {
            if (trimmedItem[key]) {
              trimmedItem[key] = trimmedItem[key].trim();
            }
          });
          trimmedItem.userType = 'gasAgent';
          return trimmedItem;
        });
        setImportedUsers(trimmedResult);
      },
    });
  };

  const importUsers = async () => {
    setError(undefined);
    setLoading(true);
    Promise.all(importedUsers.map((user) => updateUser(user)))
      .then(() => setUsersImported(true))
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Seo title="User Management" />
      <AcknowledgeDialog
        open={usersImported}
        title="Users Imported!"
        content="All users have been imported"
        onClose={() => setUsersImported(false)}
      />
      <LoadingIndicator loading={loading} />
      <Grid container direction="column" spacing={5}>
        <Grid item container direction="row" justifyContent="flex-end">
          <Grid item>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept=".csv"
            />
            <Button color="primary" variant="contained" onClick={selectCSV}>
              Select CSV File
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Grid item>
            <ErrorDialog error={error} />
          </Grid>
        )}
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Table<User>
              columns={COLUMNS}
              data={importedUsers}
              onRowClick={(user) =>
                navigate(`${ROUTES.ADMIN_USER_DETAIL}/${user.username}`)
              }
            />
          </Grid>
          {importedUsers.length > 0 && (
            <Grid item container direction="row" justifyContent="flex-end">
              <Button
                color="primary"
                disabled={loading}
                variant="contained"
                onClick={importUsers}
                size="large"
              >
                Import
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UserImport;
