import React from 'react';
import { Router } from '@reach/router';

import UserImport from './UserImport';
import UserDetail from './UserDetail';
import NotFound from '../../../NotFound';
import AdminRoute from '../../../AdminRoute';

const UserManagement = () => {
  return (
    <Router>
      <AdminRoute path="/import" component={UserImport} />
      <AdminRoute path="user/:username" component={UserDetail} />
      <AdminRoute default component={NotFound} />
    </Router>
  );
};

export default UserManagement;
