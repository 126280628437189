import React from 'react';
import Button from '@material-ui/core/Button';

interface DeleteButtonProps {
  children: string;
  onClick: () => void;
}

const DeleteButton = ({ children, onClick }: DeleteButtonProps) => (
  <Button variant="contained" color="primary" onClick={onClick} size="small">
    {children}
  </Button>
);

export default DeleteButton;
