import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';

import Unauthorised from './screens/403';
import { useAuthentication } from '../hooks/useAuthentication';
import { ROUTES } from '../utils/constants';

interface AdminRouteProps extends RouteComponentProps {
  component: any;
}

const AdminRoute = ({
  component: Component,
  location,
  ...rest
}: AdminRouteProps) => {
  const { user, isAdmin } = useAuthentication();
  if (!user) {
    navigate(ROUTES.LOGIN);
    return null;
  }

  if (!isAdmin) {
    return <Unauthorised />;
  }

  return <Component {...rest} />;
};

export default AdminRoute;
