import React from 'react';
import { Router } from '@reach/router';

import AdminHome from '../../components/screens/admin';
import UserManagement from '../../components/screens/admin/UserManagement';
import FileManagement from '../../components/screens/admin/FileManagement';
import AdminRoute from '../../components/AdminRoute';

const Admin = () => {
  return (
    <Router basepath="/admin">
      <AdminRoute path="/" component={AdminHome} />
      <AdminRoute path="user-management/*" component={UserManagement} />
      <AdminRoute path="file-management/*" component={FileManagement} />
    </Router>
  );
};

export default Admin;
