import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { User } from '../../../../types';
import RightDrawer from '../../../RightDrawer';
import LoadingIndicator from '../../../LoadingIndicator';
import { createUser, updateUser } from '../../../../client/aws';
import ErrorDialog from '../../../ErrorDialog';
import { USER_CUSTOM_ATTRIBUTES } from '../../../../utils/constants';

const ADMIN = 'admin';
const INTERNAL = 'internal';
const GAS_AGENT = 'gasAgent';

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username required'),
  password: Yup.string().required('Password required'),
  userType: Yup.string().required('User Type required'),
});

interface UserDrawerProps {
  open: boolean;
  user?: User;
  onClose: (shouldRefresh: boolean) => void;
}

const getInitialValues = (user: User) => ({
  id: '',
  username: user?.username || '',
  password: '',
  enabled: true,
  status: '',
  email: user?.email || '',
  fullName: user?.fullName || '',
  phone_number: user?.phone_number || '',
  isAdmin: user?.isAdmin || false,
  userType: user?.userType || GAS_AGENT,
  ...USER_CUSTOM_ATTRIBUTES.reduce((acc, { field }) => {
    acc[field] = user && user[field] ? user[field] : '';
    return acc;
  }, {}),
});

const UserDrawer = ({ open, user, onClose }: UserDrawerProps) => {
  const [error, setError] = useState<string>();
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik<User>({
    validationSchema: user ? undefined : ValidationSchema,
    initialValues: getInitialValues(user),
    onSubmit: async (formValues) => {
      setError(undefined);
      try {
        if (user) {
          // updating the user
          await updateUser(formValues);
        } else {
          // creating a new user
          await createUser(formValues);
        }
        onClose(true);
      } catch (err) {
        setError(err);
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetForm({ values: getInitialValues(user) }), [open, user]);

  return (
    <RightDrawer
      open={open}
      onClose={() => onClose(false)}
      title={user ? 'Edit User' : 'New User'}
    >
      <LoadingIndicator loading={isSubmitting} />
      {error && <ErrorDialog error={error} />}
      <form name="new-user" onSubmit={handleSubmit} noValidate>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              required
              fullWidth
              id="username"
              name="username"
              label="User Name"
              disabled={user !== undefined}
              value={values.username}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
            />
          </Grid>
          {!user && (
            <Grid item>
              <TextField
                required
                fullWidth
                id="password"
                name="password"
                label="Temporary Password"
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
          )}
          {values.userType === GAS_AGENT && (
            <Grid item>
              <TextField
                fullWidth
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                disabled={isSubmitting}
                value={values.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.phone_number && Boolean(errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          {values.userType !== GAS_AGENT && (
            <Grid item>
              <TextField
                fullWidth
                id="fullName"
                name="fullName"
                label="Full Name"
                value={values.fullName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={touched.fullName && errors.fullName}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              required
              fullWidth
              name="userType"
              id="userType"
              select
              label="User Type"
              SelectProps={{
                value: values.userType,
                onChange: handleChange,
              }}
              error={touched.userType && Boolean(errors.userType)}
              helperText={touched.userType && errors.userType}
            >
              <MenuItem value={GAS_AGENT}>Gas Agent</MenuItem>
              <MenuItem value={INTERNAL}>Internal</MenuItem>
              <MenuItem value={ADMIN}>Admin</MenuItem>
            </TextField>
          </Grid>
          {values.userType === GAS_AGENT &&
            USER_CUSTOM_ATTRIBUTES.map(({ field, title, options }) => (
              <Grid item key={field}>
                {!options ? (
                  <TextField
                    fullWidth
                    id={field}
                    name={field}
                    label={title}
                    disabled={
                      isSubmitting ||
                      (field === 'equipmentPartnerAccountNumber'
                        ? values.isEquipmentPartner === 'No'
                        : false)
                    }
                    value={values[field]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched[field] && Boolean(errors[field])}
                    helperText={touched[field] && errors[field]}
                  />
                ) : (
                  <TextField
                    fullWidth
                    name={field}
                    id={field}
                    select
                    label={title}
                    SelectProps={{
                      value: values[field],
                      onChange: handleChange,
                    }}
                    error={touched[field] && Boolean(errors[field])}
                    helperText={touched[field] && errors[field]}
                  >
                    <MenuItem key="" value="">
                      N/A
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            ))}
          <Grid item container justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Button
                onClick={() => onClose(false)}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid}
              >
                {user ? 'Update' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </RightDrawer>
  );
};

export default UserDrawer;
