import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import colors from '../../../../styles/colors';

const useStyles = makeStyles({
  root: {
    height: '16px',
  },
  barColorPrimary: {
    backgroundColor: colors.danger,
  },
});

interface ProgressIndicatorProps {
  visible: boolean;
  progress: number;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  visible,
  progress,
}) => {
  const classes = useStyles();

  if (!visible) return null;
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      left={0}
      top={0}
      zIndex={100000}
      bgcolor="rgba(0, 0, 0, 0.2)"
      data-testid="loading-indicator"
    >
      <div style={{ width: '60%' }}>
        <LinearProgress
          classes={classes}
          variant="determinate"
          value={progress}
        />
      </div>
    </Box>
  );
};
export default ProgressIndicator;
